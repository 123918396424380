import ValidService from "../../../service/ValidService";

let model = {};

// dữ liệu form
model.dataForm = {
    user: null,
};


model.tableRules = {
    lengthMenu: [10, 20, 50, 100],
    allowPaging: true,
    allowSorting: true,
    allowSelect: false,
    showFormSearch: false,
    showUrl: true,
    total: 0,
    page: 1,
    limit: 20,
    offset: 0,
    sort: undefined,
    sort_type: undefined,
    defaultSort: [undefined, "ascending"],
    filters: "",
    dataSearch: {
        value: {
            user: "",
        },
        valid: {
            user: [ValidService.checkNameAllowVN],
        },
        operator: {
            user: ":regex_i:",
        },
    },
};

export default model;
